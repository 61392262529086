import { html, css } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { Lang, Fetcher } from 'Utils';

import config from './config.js';

class AdminNotificationEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        label {
          font-size:0.9em;
          padding-left:5px;
        }

        .padding {
          padding-left:5px;
        }

        box-styled[variant="warning"] {
          font-size:0.9em;
        }

        pre {
          font-size:0.6em;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            cancel:'Cancel',
            add:'Create a notification',
            edit:'Edit',
            save:'Save',
            send:'Send'
          },
        },
        french:{
          translation: {
            cancel:'Annuler',
            add:'Créer une notification',
            edit:'Modifier',
            save:'Enregistrer',
            send:'Envoyer'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.eventUpdated = config.eventUpdated;
    this.itemTitleField = config.itemTitleField;
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
  }

  getItemTitle() {
    return this.item.name;
  }

  getTranslatePayload() {
    const payload = {
      title: Lang.lookup(this.item, 'title'),
      content: Lang.lookup(this.item, 'content'),
    }
    return payload;
  }

  onTranslated(ev) {
    this._lookupField('title', 'en').value = ev.detail.title || '';
    this._lookupField('content', 'en').value = ev.detail.content  || '';
    this.compareFields();
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) {
      input.setCustomValidity(msg);
      input.reportValidity();
    }
  }

  editorUpdated() {
    console.log('editorUpdated');
    this.compareFields();
  }

  async handleTest() {
    const bt = this.qs('#test_button');
    bt.loading = true;
    const url = `${this.apiEndpoint}/${this.item._id}/test`;
    const response = await Fetcher.post(url, {
      test_as_id: this.qs('[name="test_as"]').value,
      test_recipient: this.qs('[name="test_recipient"]').value,
      lg: this.qs('languages-selector').lg
    });
    bt.loading = false;

    if (response?.data) {
      this.qs('#modal-test-result').show();
      this.qs('#modal-test-result').querySelector('pre').textContent = JSON.stringify(response.data, null, 4);
    }

  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer large>
        <form>
          <br/>
          <tab-group class="main" .item=${this.item}>
            <sl-tab slot="nav" panel="general">Général</sl-tab>
            <sl-tab slot="nav" panel="test">Tester ...</sl-tab>

            <sl-tab-panel name="general">
              <tab-group-lg level="lg" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
                <br/>
                <sl-input size="small" name="title" class="labol" label="Titre""></sl-input><br/>
                <cherry-editor size="small" name="content" class="labol" label="Contenu"></cherry-editor><br/>
              </tab-group-lg>
            </sl-tab-panel>

            <sl-tab-panel name="test">
              <div class="padding">
                ${this.pendingChanges 
                  ? html`<box-styled variant="warning">Vous avez des modifications non enregistrées.</box-styled><br/>`
                  : ''
                }
                
                <sl-button id="test_button" size="small" disabled @click="${this.handleTest}">
                  <m-icon slot="suffix" name="send"></m-icon>
                  ${this._tl('send')}
                </sl-button>

              </div>
            </sl-tab-panel>

          </tab-group>
        </form>

        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
        
      </modal-drawer>

      <modal-dialog id="modal-test-result" label="Détail SMTP">
        <div class="padding">
          <p>Le mail a été envoyé avec succès :</p>
          <pre></pre>
        </div>
        <sl-button slot="bt2" variant="primary" close="true">OK</sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('admin-notification-edit', AdminNotificationEdit);