import { html, css } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { Fetcher, UrlParams } from 'Utils';
import config from './config.js';

class RedisEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        box-json {
          max-height: 70vh;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            cancel:'Cancel',
            edit:'Edit',
            save:'Save',
            delete:'Delete',
            title:'Informations',
          },
        },
        french:{
          translation: {
            cancel:'Annuler',
            edit:'Modifier',
            save:'Enregistrer',
            delete:'Supprimer',
            title:'Informations',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.itemTitleField = config.itemTitleField;
    this.eventUpdated = config.eventUpdated;
  }

  showDeleteConfirm() {
    this.qs('#modal-delete').show();
  }
  
  async deleteItem() {
    this.qs('#modal-delete').hide();
    const idk = UrlParams.get('idk');
    const response = await Fetcher.delete(`${config.apiEndpoint}/${idk}`);
    window.dispatchEvent(new CustomEvent(this.eventUpdated, { detail: { action: 'delete', idk: idk } }));
    this.qs('modal-drawer').hide();
  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer name="modal-tag-edit" @close=${this.onClose}>
        <style>${this.constructor.styles}</style>
        <form>
          <tab-group level="tl" class="main">
            <sl-tab slot="nav" panel="text">${this._tl('title')}</sl-tab>

            <sl-tab-panel name="text">
              <box-json .value=${this.item}></box-json>
            </sl-tab-panel>
          </tab-group>
        </form>

        <sl-button slot="bt1" variant="danger" @click="${this.showDeleteConfirm}">${this._tl('delete')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('close')}</sl-button>
        
      </modal-drawer>

      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          Êtes-vous sûr de vouloir supprimer &laquo;<span class="itemTitle"></span>&raquo;?
          <br/><br/>
          <ul>
            <li>Cette action est irréversible, réservée aux développeurs et administrateurs.</li>
          </ul>
        </div>

        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click=${this.deleteItem}>${this._tl('delete')}</sl-button>
        
      </modal-dialog>
    `;
  }

}

customElements.define('admin-redis-edit', RedisEdit);