import { PageElement, html, css } from 'Elements';
import TableBasic from 'Components/abstract/TableBasic.js';
import { Lang } from 'Utils';

import config from './config.js';
import './edit.js';


class AdminCustomersEmailTemplateList extends TableBasic {
  static get translations() {
    return [
      super.translations,
      [
        {
          english:{
            translation: {
              add:'Add a template',
              empty:'No template available',
              duplicate_new_name:'New template name',
              test:'Test',
            },
          },
          french:{
            translation: {
              add:'Ajouter un template',
              empty:'Aucune template disponible',
              duplicate_new_name:'Nom du nouveau template',
              test:'Tester',
            }
          }
        }
      ]
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.itemModel = { };
    this.itemTitleField = config.itemTitleField;
    this.eventUpdated = config.eventUpdated;
  }

  _renderEditComponent() {
    return html`<admin-email-template-edit id="modal-edit"></admin-email-template-edit>`;
  }

  _getTableHeader() {
    const totalCount = this.items?.length || 0;
    const totalText = totalCount >= 1 ? 'templates' : 'template';
    return `${totalCount} ${totalText}`;
  }
  
  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table>
        <thead>
          <tr>
            <th>Modèle</th>
            <th>Sujet</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}" data-id="${item._id}">
            <td>${item.name}</td>
            <td>${Lang.lookup(item, 'subject')}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}"><m-icon slot="prefix" name="edit"></m-icon>${this._tl('modify')}</sl-menu-item>
                  <sl-menu-item @click="${(ev) => this._duplicateItemShow(ev, item)}"><m-icon slot="prefix" name="content_copy"></m-icon>${this._tl('duplicate')}</sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}"><m-icon slot="prefix" name="delete"></m-icon>${this._tl('remove')}</sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
      <br/><br/><br/>
    `;
  }

}

customElements.define('page-admin-customers-tpl-email-list', AdminCustomersEmailTemplateList);


class TemplatesEmail extends PageElement {

  showHelp() {
    this.qs('modal-drawer[name="help"]').show();
  }

  render() {
    return html`
      <section-header micon="mail" backroute="../">
        Modèles email
        <m-icon slot="right" clickable name="help" @click="${this.showHelp}"></m-icon>
      </section-header><br/>
      <page-admin-customers-tpl-email-list></page-admin-customers-tpl-email-list>        

      <modal-drawer name="help" label="Aide">
        <p>
          <sl-details summary="Blocage SMTP Gandi">
            Les mails sont envoyés via le serveur SMTP de Gandi. Il est possible que Gandi bloque l'envoi de mail si le contenu est considéré comme du spam.<br><br/>
            Les raisons possibles / combinées sont :<br/></br>
            <!-- une priorité différente de "normal"<br> -->
            - le contenu texte, ce qui est dit dans le mail ...<br>
            - alias mail utilisé pour le destinataire (xxx.xx+alias@domain.com)<br>
            
          </sl-details>
          <br/>
        </p>
      </modal-drawer>
    `;
  }
}

customElements.define('page-sys-tpl-email', TemplatesEmail);
