import { PageElement, html } from 'Elements';
import TableBasic from 'Components/abstract/TableBasic.js';
import { Lang } from 'Utils';

import config from './config.js';
import './edit.js';


class AdminNotificationsList extends TableBasic {
  static get translations() {
    return [
      super.translations,
      [
        {
          english:{
            translation: {
              add:'Create a notification',
              empty:'No notification available',
              duplicate_new_name:'New notification',
              test:'Test',
            },
          },
          french:{
            translation: {
              add:'Créer une notification',
              empty:'Aucune notification disponible',
              duplicate_new_name:'Titre de la notification',
              test:'Tester',
            }
          }
        }
      ]
    ];
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.itemModel = { };
    this.itemTitleField = config.itemTitleField;
    this.eventUpdated = config.eventUpdated;
  }

  _renderEditComponent() {
    return html`<admin-notification-edit id="modal-edit"></admin-notification-edit>`;
  }

  _getTableHeader() {
    const totalCount = this.items?.length || 0;
    const totalText = totalCount >1 ? 'notifications' : 'notification';
    return `${totalCount} ${totalText}`;
  }
  
  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table>
        <thead>
          <tr>
            <th>Titre</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}" data-id="${item._id}">
            <td>${Lang.lookup(item, 'title')}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}"><m-icon slot="prefix" name="edit"></m-icon>${this._tl('modify')}</sl-menu-item>
                  <sl-menu-item @click="${(ev) => this._duplicateItemShow(ev, item)}"><m-icon slot="prefix" name="content_copy"></m-icon>${this._tl('duplicate')}</sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}"><m-icon slot="prefix" name="delete"></m-icon>${this._tl('remove')}</sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
      <br/><br/><br/>
    `;
  }

}

customElements.define('page-admin-notifications-list', AdminNotificationsList);


class AdminNotificationsPage extends PageElement {
  render() {
    return html`
        <section-header micon="notifications" backroute="../">Notifications</section-header><br/>
        <page-admin-notifications-list></page-admin-notifications-list>        
    `;
  }
}

customElements.define('page-sys-notifications', AdminNotificationsPage);
